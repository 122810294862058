import axios from 'axios';
import isUrl from 'is-url';
import joinUrl from 'proper-url-join';
import qs from 'querystring';
import { checkIsTokenExpired, clearToken } from './utility';

class APIRequest {
  constructor(baseURL, httpsAgent, httpAgent, getToken) {
    if (!isUrl(baseURL)) throw new Error('The base URL provided is not valid');

    this.baseURL = baseURL;
    this.httpsAgent = httpsAgent;
    this.httpAgent = httpAgent;
    this.getToken = getToken;
  }

  async request(method, url, data) {
    let callURL = joinUrl(this.baseURL, url, { trailingSlash: false });

    const headers = {};
    const token = this.getToken();

    if (token) {
      headers['Authorization'] = `Bearer ${token.get('accessToken')}`;
    }

    if (['POST', 'PUT'].indexOf(method) !== -1) {
      headers['Content-Type'] = 'application/json';
    } else if (Object.keys(data).length && data.constructor === Object) {
      callURL = joinUrl(callURL, { trailingSlash: true, query: data });
    }

    return axios(callURL, {
      httpsAgent: this.httpsAgent,
      httpAgent: this.httpAgent,
      method,
      data,
      headers
    }).then(
      (response) => {
        if (response.status >= 400) {
          // check for 4XX, 5XX, wtv
          return Promise.reject(new Error(response.statusText));
        }

        if (response.status >= 200 && response.status <= 202) {
          return response.data;
        }

        return {};
      },
      async (error) => {
        const response = error.response;
        // If error 401 UNAUTHORIZED (invalid token) to refresh token & retry calling the API
        if (response.status === 401 && response.data === 'Access token is invalid') {
          const refreshed = await this.request('POST', '/token/refresh', {
            refreshToken: token.get('refreshToken')
          });

          window.localStorage.setItem('id_token', refreshed.id_token);
          window.localStorage.setItem('access_token', refreshed.access_token);
          window.localStorage.setItem('refresh_token', refreshed.refresh_token);

          headers['Authorization'] = `Bearer ${refreshed.access_token}`;

          return axios(callURL, {
            httpsAgent: this.httpsAgent,
            httpAgent: this.httpAgent,
            method,
            data,
            headers
          }).then((response) => {
            if (response.status >= 400) {
              // check for 4XX, 5XX, wtv
              return Promise.reject(new Error(response.statusText));
            }

            if (response.status >= 200 && response.status <= 202) {
              return response.data;
            }

            return {};
          });
        }

        return Promise.reject(error);
      }
    );
  }

  async checkToken() {
    const token = this.getToken();
    const accessToken = token.get('accessToken');

    if (window.DEBUG_TOKEN) {
      console.log('----------');
      console.log('token', !!token);
      console.log('accessToken', !!accessToken);
    }

    if (!accessToken) {
      return;
    }

    const isExpired = checkIsTokenExpired(accessToken);

    if (isExpired) {
      console.log('Refreshing token...');

      try {
        const refreshed = await this.request('POST', '/token/refresh', {
          refreshToken: token.get('refreshToken')
        });

        window.localStorage.setItem('id_token', refreshed.id_token);
        window.localStorage.setItem('access_token', refreshed.access_token);
        window.localStorage.setItem('refresh_token', refreshed.refresh_token);

        console.log('Token refreshed successfully!');
      } catch (error) {
        console.log('Token fail to refresh!');
        clearToken();
        throw error;
      }
    }
  }

  async send(method, url, data = {}, checkToken = true) {
    if (checkToken) {
      await this.checkToken();
    }

    const response = await this.request(method, url, data);
    return response;
  }
}

class HSBB {
  constructor(options) {
    this.options = options;
    this.baseURL = options.baseURL;

    this.api = new APIRequest(options.baseURL, options.httpsAgent, options.httpAgent, options.getToken);
  }

  ping = () => {
    return this.api.send('GET', `/ping`);
  };

  setLastLogin = () => {
    return this.api.send('PATCH', `/users/lastLogin`);
  };
  getVersion = () => {
    return this.api.send('GET', `/version`);
  };

  getAppSettings = () => {
    return this.api.send('GET', '/app/settings');
  };

  getUserSetting = () => {
    return this.api.send('GET', `/user/setting`);
  };

  getProviderConfig = () => {
    return this.api.send('GET', '/provider/config');
  };

  // profiles
  createProfile = (userData) => {
    return this.api.send('POST', '/radius/profiles', userData);
  };

  getProfiles = (options) => {
    return this.api.send('GET', '/radius/profiles', options);
  };

  getProfile = (id) => {
    return this.api.send('GET', `/radius/profiles/${id}`);
  };

  updateProfile = (id, userData) => {
    return this.api.send('PUT', `/radius/profiles/${id}`, userData);
  };

  disableProfile = (id) => {
    return this.api.send('DELETE', `/radius/profiles/${id}`);
  };

  enableProfile = (id) => {
    return this.api.send('PUT', `/radius/profiles/${id}/enable`);
  };

  getSessionHistory = (username, options) => {
    return this.api.send('GET', `/radius/profiles/session-history/${username}`, options);
  };

  getSuggestedServiceId = (fullName, operator, ipv4) => {
    return this.api.send(
      'GET',
      `/radius/profiles/suggested/serviceid/?fullName=${fullName}&operator=${operator}&ipv4=${ipv4}`
    );
  };

  bookAppointment = (session, isReschedule) => {
    return this.api.send('POST', `/appointment`, {
      session,
      isReschedule
    });
  };

  // appointment settings
  getAppointmentProvidersConfig = () => {
    return this.api.send('GET', '/appointment/provider-config');
  };

  updateAppointmentProvidersConfig = (config) => {
    return this.api.send('PUT', '/appointment/provider-config', config);
  };

  getAppointmentSlots = (options) => {
    return this.api.send('GET', '/appointment/check-slots', options);
  };

  // groups
  getGroups = () => {
    return this.api.send('GET', '/radius/groups');
  };

  getGroupsSummary = () => {
    return this.api.send('GET', '/radius/groups/summary');
  };

  // applications
  getApplications = (options) => {
    return this.api.send('GET', '/applications', options);
  };

  createReportRequest = (options) => {
    return this.api.send('POST', '/reports/report-request', options);
  };

  getReportRequestById = (id) => {
    return this.api.send('GET', `/reports/report-request/${id}`);
  };

  getReportRequestList = (reportRequestId) => {
    return this.api.send('GET', `/reports/report-request-list/${reportRequestId}`);
  };

  getApplicationHistory = (appId) => {
    return this.api.send('GET', `/applications/${appId}/history`);
  };

  getApplication = (session) => {
    return this.api.send('GET', `/applications/${session}`);
  };

  updateLeads = (session, updateData) => {
    return this.api.send('PUT', `/applications/leads/${session}`, updateData);
  };

  convertLead = (session) => {
    return this.api.send('POST', `/applications/leads/${session}/convert`);
  };

  updateApplication = (session, updateData) => {
    return this.api.send('PUT', `/applications/${session}`, updateData);
  };

  createApplication = (addData) => {
    return this.api.send('POST', `/applications`, addData);
  };

  reEnableApplication = (session, updateData) => {
    return this.api.send('PUT', `/applications/${session}/re-enable`, updateData);
  };

  submitApplication = (session, data) => {
    return this.api.send('PUT', `/applications/${session}/submit`, data);
  };

  cancelApplication = (session, updateData) => {
    return this.api.send('PUT', `/applications/${session}/cancel`, updateData);
  };

  terminateApplication = (session, updateData) => {
    return this.api.send('DELETE', `/applications/${session}/terminate`, updateData);
  };

  changeApplicationPlan = (session, updateData) => {
    return this.api.send('PUT', `/applications/${session}/change-plan`, updateData);
  };

  onHoldApplication = (session, updateData) => {
    return this.api.send('PUT', `/applications/${session}/onHold`, updateData);
  };

  reactivateApplication = (session, updateData) => {
    return this.api.send('PUT', `/applications/${session}/reactivate`, updateData);
  };

  updateDelayApplication = (session, updateData) => {
    return this.api.send('PUT', `/applications/${session}/updateDelayApplication`, updateData);
  };

  // history
  appendHistory = (session, updateData) => {
    return this.api.send('PUT', `/applications/${session}/history`, updateData);
  };

  // upload
  getAppFileUploadUrl = (sessionId, fileType, fileName) => {
    return this.api.send('GET', `/applications/${sessionId}/s3UploadUrl`, {
      fileType,
      fileName
    });
  };

  gets3FileUrl = (file, session) => {
    return this.api.send('GET', `/applications/files/download`, {
      file,
      session
    });
  };

  getTDCUploadUrl = (fileType, fileName) => {
    return this.api.send('GET', `/TDCUploadUrl`, {
      fileType,
      fileName
    });
  };

  getContractRebateUpload = (sessionId, fileType, fileName) => {
    return this.api.send('GET', `/applications/${sessionId}/contractFileS3UploadUrl`, {
      fileType,
      fileName
    });
  };

  // address search
  searchAddress = (query, start = 0, referenceSearchId) => {
    return this.api.send('GET', `/addr-search`, {
      q: query,
      start,
      referenceSearchId
    });
  };

  // users
  createUser = (userData) => {
    return this.api.send('POST', '/users', userData);
  };

  getUsers = (options) => {
    return this.api.send('GET', '/users', options);
  };

  getUser = (id) => {
    return this.api.send('GET', `/users/${id}`);
  };

  updateUser = (id, userData) => {
    return this.api.send('PUT', `/users/${id}`, userData);
  };

  deleteUser = (id) => {
    return this.api.send('DELETE', `/users/${id}`);
  };

  // offerings
  getPrimaryOfferings = (subscriberType) => {
    return this.api.send('GET', `/offerings/${subscriberType}`);
  };

  getSupplementaryOfferings = (subsciberType, primaryOfferId) => {
    return this.api.send('GET', `/offerings/${subsciberType}/supplementary/${primaryOfferId}`);
  };

  // plans
  getPlan = (planId) => {
    return this.api.send('GET', `/plans/${planId}`);
  };

  getPlans = (options) => {
    return this.api.send('GET', '/plans', options);
  };

  getEligibleChangePlans = (options) => {
    return this.api.send('GET', '/plans/eligible-change-plans', options);
  };

  createPlan = (data) => {
    return this.api.send('POST', '/plans', data);
  };

  updatePlan = (planId, plan) => {
    return this.api.send('PUT', `/plans/${planId}`, plan);
  };

  getPlanImgSignedUrl = (fileName) => {
    return this.api.send('GET', `/plans/planImgSignedUrl/`, { fileName });
  };

  updatePlanStatus = (planId) => {
    return this.api.send('PUT', '/plans/update-status', { planId: planId });
  };

  updatePlanPromotions = (planId, promotions) => {
    return this.api.send('PUT', `/plans/${planId}/promotions`, promotions);
  };

  getPromotions = () => {
    return this.api.send('GET', '/promotions');
  };

  getOperatorAndSpeed = () => {
    return this.api.send('GET', '/plans/operator-speed');
  };

  updatePromotions = (promotions) => {
    return this.api.send('PUT', '/promotions', promotions);
  };

  getPromotionEligibilityInformation = (promotionId, data) => {
    return this.api.send('GET', `/promotions/${promotionId}/eligibility-info`, data);
  };

  updatePlanEmail = (planId, emailData) => {
    return this.api.send('PUT', `/plans/${planId}/email`, emailData);
  };

  // sim serial numbers
  getSimSerialNumbers = (options) => {
    return this.api.send('GET', '/simserialnumbers', options);
  };

  getSerialNumber = (serialNumber) => {
    return this.api.send('GET', `/simserialnumbers/${serialNumber}`);
  };

  createSimSerialNumber = (serialNumber) => {
    return this.api.send('POST', '/simserialnumbers', serialNumber);
  };

  updateSimSerialNumber = (data) => {
    return this.api.send('PUT', `/simserialnumbers`, data);
  };

  deleteSimSerialNumber = (serialNumber) => {
    return this.api.send('DELETE', `/simserialnumbers/${serialNumber}`);
  };

  getSimSerialNumbersUploadsUrl = (fileType, fileName) => {
    return this.api.send('GET', `/simserialnumbers/s3/uploadurl`, {
      fileType,
      fileName
    });
  };

  uploadBatchSimSerialNumber = (fileKey) => {
    return this.api.send('PUT', `/simserialnumbers/s3/batchupload`, { fileKey });
  };

  // installers
  getInstallers = (options) => {
    return this.api.send('GET', '/installers', { ...options });
  };

  getInstaller = (installerId) => {
    return this.api.send('GET', `/installers/${installerId}`);
  };

  createInstaller = (data) => {
    return this.api.send('POST', '/installers', data);
  };

  updateInstaller = (installerId, data) => {
    return this.api.send('PUT', `/installers/${installerId}`, data);
  };

  getInstallerRegions = () => {
    return this.api.send('GET', '/installers/config/regions');
  };

  updateInstallerRegions = (config) => {
    return this.api.send('PUT', '/installers/config/regions', config);
  };

  getOperatorLists = () => {
    return this.api.send('GET', '/installers/operator-list');
  };

  getCustomerDetails = (IdType, IdNumber, session) => {
    return this.api.send('GET', '/csg/customer-details', {
      IdType,
      IdNumber,
      session
    });
  };

  getCSGLOVList = (csgListName) => {
    return this.api.send('GET', `/csg/lov-list/${csgListName}`);
  };

  getCsgLovDetails = (csgLovname, value) => {
    return this.api.send('GET', `/csg/lov-details/${csgLovname}/${value}`);
  };

  getCityDetails = (csgLovname, value) => {
    return this.api.send('GET', `/csg/lov-city/${csgLovname}/${value}`);
  };

  getCsgLovValue = (csgLovname, valueId) => {
    return this.api.send('GET', `/csg/lov-value/${csgLovname}/${valueId}`);
  };

  createCRMProfle = (appSession) => {
    return this.api.send('GET', `/csg/create-crm-profile`, {
      appSession
    });
  };

  createDummyCRMProfile = (appSession) => {
    return this.api.send('POST', '/csg/create-dummy-crm-profile', {
      appSession
    });
  };

  checkDummyCRMProfileStatus = (appSession) => {
    return this.api.send('GET', '/csg/check-dummy-crm-profile-status', {
      appSession
    });
  };

  checkAccountDue = (IdType, IdNumber, msisdn, subscriberType) => {
    subscriberType = subscriberType === 'Ambassador' ? 3 : subscriberType === 'Mass' ? 1 : 9;
    return this.api.send('GET', `/csg/check-due`, {
      IdType,
      IdNumber,
      msisdn,
      subscriberType
    });
  };

  // for staging env only
  createRadiusActivity = (data) => {
    return this.api.send('POST', `/radius/profiles/create-activity`, data);
  };

  // corporate application
  getCorporateApplications = (options) => {
    return this.api.send('GET', '/corporateApplications', options);
  };

  getCorporateApplication = (session) => {
    return this.api.send('GET', `/corporateApplications/${session}`);
  };

  createCorporateApplication = (addData) => {
    return this.api.send('POST', `/corporateApplications`, addData);
  };

  updateCorporateApplication = (session, updateData) => {
    return this.api.send('PUT', `/corporateApplications/${session}`, updateData);
  };

  searchCorporateAddress = (query, start = 0) => {
    return this.api.send('GET', `/addr-search/corporateFibreAddress`, {
      q: query,
      start
    });
  };

  terminateCorporateApplication = (session, updateData) => {
    return this.api.send('DELETE', `/corporateApplications/${session}/terminate`, updateData);
  };

  appendCorporateHistory = (session, updateData) => {
    return this.api.send('PUT', `/corporateApplications/${session}/history`, updateData);
  };

  getCorporateApplicationHistory = (session) => {
    return this.api.send('GET', `/corporateApplications/${session}/history`);
  };

  // settings
  getSetting = (key) => {
    return this.api.send('GET', `/public/setting/${key}`);
  };

  refreshToken = (token) => {
    return this.api.send('POST', '/token/refresh', { refreshToken: token }, false);
  };

  // IP Addresses
  getAllIpAddresses = (options) => {
    const query = qs.stringify(options);
    return this.api.send('GET', `/ip-address?${query}`);
  };

  getIpAddressesByStatus = (status) => {
    return this.api.send('GET', `/ip-address/status/${status}`);
  };

  getIpAddressDownloadLink = () => {
    return this.api.send('GET', 'ip-address/download');
  };

  getNextAvailableIp = () => {
    return this.api.send('GET', '/ip-address/next');
  };

  assignIpAddress = ({ ipv4, serviceId, session, newAppl, speedInMbps }) => {
    return this.api.send('PUT', `ip-address/assign/${ipv4}`, {
      serviceId,
      session,
      newAppl,
      speedInMbps
    });
  };

  unassignIpAddress = (ipv4, session) => {
    return this.api.send('PUT', `ip-address/unassign/${ipv4}`, { session });
  };

  getIpAddressByIpv4 = (ipv4) => {
    return this.api.send('GET', `ip-address/ipv4/${ipv4}`);
  };

  getAccountMsisdnList = (session) => {
    return this.api.send('GET', `applications/${session}/account-msisdn-list`);
  };

  executePortOut = ({ session, portOutTerminationDate, portOutAccountOwnerMsisdn }) => {
    return this.api.send('PUT', `applications/${session}/port-out/execute`, {
      portOutTerminationDate,
      portOutAccountOwnerMsisdn
    });
  };

  getAddressUploadSignedUrl = ({ fileName, fileType, operator }) => {
    return this.api.send('GET', 'address-upload/signedUrl', {
      fileName,
      fileType,
      operator
    });
  };

  getSerialUploadSignedUrl = ({ fileName, fileType }) => {
    return this.api.send('GET', 'serial-upload/signedUrl', {
      fileName,
      fileType
    });
  };

  getSafUploadSignedUrl = ({ fileName, fileType }) => {
    return this.api.send('GET', 'saf-upload/signedUrl', {
      fileName,
      fileType
    });
  };

  getApplicationIsDelayed = (session) => {
    return this.api.send('GET', `/applications/${session}/isDelayed`);
  };

  changeInstaller = (session, newInstallerId) => {
    return this.api.send('PUT', `/applications/${session}/change-installer`, {
      newInstallerId
    });
  };

  updateCreatorId = (session, creatorId) => {
    return this.api.send('PUT', `/applications/${session}/creator-id`, {
      creatorId
    });
  };

  // Promotions-config
  getPromotionConfig = (planId) => {
    return this.api.send('GET', `/promotion-config/${planId}`);
  };

  getAllPromotionConfig = (options) => {
    return this.api.send('GET', '/promotions-config', options);
  };

  createPromotionConfig = (data) => {
    return this.api.send('POST', '/promotions-config', data);
  };
  
  updatePromotionConfig = (promotionId, promotionConfig) => {
    return this.api.send('PUT', `/promotionId/${promotionId}`, promotionConfig);
  };

  updatePromotionStatus = (promotionId) => {
    return this.api.send('PUT', '/promotionId/update-status', { promotionId: promotionId });
  };
}

export default HSBB;
